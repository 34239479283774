<template>
    <div class="ours">
        <div class="contanier" style="padding-top: 100px;font-size:18px">
            <div style="font-size:28px;font-weight: bold;width: 100%;text-align: center;">上海医康联数字科技有限公司</div>
            <div style="margin-top: 20px;width: 100%;text-align: center;">
                <span>咨询电话：</span>
                <span>1518-958-8765</span>
            </div>
            <div style="margin-top: 20px;width: 100%;text-align: center;">
                <span>电子邮箱：</span>
                <span>jwz@aiykl.com</span>
            </div>
            <div style="margin-top: 20px;width: 100%;text-align: center;">
                <span>公司地址：</span>
                <span>上海市普陀区西康路1255号13层1314室</span>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
    .ours{
        width: 1240px;
        height: 400px;
        background-color: #fff;
        padding-bottom: 20px;
        margin: auto;
        .container{
            // margin: auto;
            padding-top: 80px;
        }
    }
</style>